<template>
  <b-container fluid>
    <b-row>
          <b-col md="12" class="mb-4">
            <h5 class="text-danger">Reason :</h5>
            <h5 class="text-primary pl-4"> {{rocData.reason.reason}} </h5>
          </b-col>
          <b-col md="12" class="mb-3">
                <i :class="['las la-check-circle font-size-22 mr-3',
                 rocData.first_status=='pending'?'text-gray' : rocData.first_status=='approved'?'text-success':
                'text-danger']"></i>
                <i  :class="['las la-check-circle font-size-22 mr-3',
                 rocData.second_status=='pending'?'text-gray' :
                 rocData.second_status=='approved'?'text-success':
                'text-danger']"></i>
                <i  :class="['las la-check-circle font-size-22 mr-3',
                 rocData.third_status=='pending'?'text-gray' :
                 rocData.third_status=='approved'?'text-success':
                'text-danger']"></i>
          </b-col>
          <b-col class="mb-4">
            <section v-if="rocData.first_status != 'pending'">
              <h5 class="mb-2">First</h5>
              <div class="border-wrong-entry p-3 mb-3">
                <b-row>
                  <b-col md="2">
                    <span class="text-primary">Status :</span>
                  </b-col>
                  <b-col md="10">
                    {{rocData.first_status}}
                  </b-col>
                  <b-col md="2">
                    <span class="text-primary">Comment :</span>
                  </b-col>
                  <b-col md="10">
                    {{rocData.first_comment}}
                  </b-col>
                  <b-col md="2">
                    <span class="text-primary">User :</span>
                  </b-col>
                  <b-col md="10">
                    {{rocData.first_user ? rocData.first_user.name : ''}}
                  </b-col>
                  <b-col md="2">
                    <span class="text-primary">Date :</span>
                  </b-col>
                  <b-col md="10">
                    {{rocData.first_action_at ? timeConvert(rocData.first_action_at) : ''}}
                  </b-col>
                </b-row>
              </div>
            </section>
            <section v-if="rocData.second_status != 'pending'">
              <h5 class="mb-2">Secound</h5>
              <div class="border-wrong-entry p-3 mb-3">
                <b-row>
                  <b-col md="2">
                    <span class="text-primary">Status :</span>
                  </b-col>
                  <b-col md="10">
                    {{rocData.second_status}}
                  </b-col>
                  <b-col md="2">
                    <span class="text-primary">Comment :</span>
                  </b-col>
                  <b-col md="10">
                    {{rocData.second_comment}}
                  </b-col>
                  <b-col md="2">
                    <span class="text-primary">User :</span>
                  </b-col>
                  <b-col md="10">
                    {{rocData.second_user ? rocData.second_user.name : ''}}
                  </b-col>
                  <b-col md="2">
                    <span class="text-primary">Date :</span>
                  </b-col>
                  <b-col md="10">
                    {{rocData.second_action_at ? timeConvert(rocData.second_action_at) : ''}}
                  </b-col>
                </b-row>
              </div>
            </section>
           <section v-if="rocData.third_status != 'pending'">
            <h5 class="mb-2">Third</h5>
            <div class="border-wrong-entry p-3 mb-3">
              <b-row>
                <b-col md="2">
                  <span class="text-primary">Status :</span>
                </b-col>
                <b-col md="10">
                  {{rocData.third_status}}
                </b-col>
                <b-col md="2">
                  <span class="text-primary">Comment :</span>
                </b-col>
                <b-col md="10">
                  {{rocData.third_comment}}
                </b-col>
                <b-col md="2">
                  <span class="text-primary">User :</span>
                </b-col>
                <b-col md="10">
                  {{rocData.third_user ? rocData.third_user.name : ''}}
                </b-col>
                <b-col md="2">
                  <span class="text-primary">Date :</span>
                </b-col>
                <b-col md="10">
                  {{rocData.third_action_at ? timeConvert(rocData.third_action_at) : ''}}
                </b-col>
              </b-row>
            </div>
           </section>
          </b-col>
          <b-col md="12"
                 v-if="rocData.first_status == 'pending' ||
                  rocData.second_status == 'pending' ||
                   rocData.third_status == 'pending'"
          >
            <span v-if="rocData.first_status !== 'rejected'">
              <span v-if="rocData.second_status !== 'rejected'">
                <span v-if="rocData.third_status !== 'rejected'">
            <b-form-group class="col-md-12" label="Write Comment:" label-for="note">
                <b-form-textarea v-model="wrongEntry.comment" name="comment"  id="comment" placeholder="comment..."></b-form-textarea>
            </b-form-group>
              <div class="d-flex  mt-4 align-items-center justify-content-end" v-if="!loadingWrongEntry">
                <b-button variant="primary" class="mr-2" type="submit" @click="sendWrongEntry('approved')" >Approved
                </b-button>
                <b-button variant="danger" type="submit" @click="sendWrongEntry('rejected')">Rejected</b-button>
              </div>
                  <div v-else>
                      <b-button variant="warning" class="w-100" disabled>
                            <b-spinner small type="grow"></b-spinner>
                            loading...
                          </b-button>
<!--                    <b-button variant="danger" type="submit" @click="sendWrongEntry('rejected')">Rejected</b-button>-->
                  </div>
                  </span>
                </span>
            </span>
          </b-col>
        </b-row>
  </b-container>
</template>
<script>
export default {
  props: {
    rocData: {}
  },
  data () {
    return {
      wrongEntry: {
        comment: '',
        status: '',
        type: '',
        entry_id: ''
      },
      loadingWrongEntry: false
    }
  },
  methods: {
    sendWrongEntry (status) {
      this.loadingWrongEntry = true
      this.wrongEntry.status = status
      this.wrongEntry.entry_id = this.rocData.id
      if (this.hasPer('membership.roc.first') && this.rocData.first_status === 'pending') {
        this.wrongEntry.type = 'first'
      } else if (this.hasPer('membership.roc.second') && this.rocData.second_status === 'pending') {
        this.wrongEntry.type = 'second'
      } else if (this.hasPer('membership.roc.third') && this.rocData.third_status === 'pending') {
        this.wrongEntry.type = 'third'
      }
      if (this.wrongEntry.type) {
        this.$emit('sendWrongEntry', this.wrongEntry)
      }
    }
  },
  created () {
    this.loadingWrongEntry = false
  }
}
</script>
<style>
.border-wrong-entry{
  border: 1px dashed gray;
  border-radius: 4px;
}
</style>
